import React from 'react';

const Typing = () => (
	<div className='typing'>
		<div className='typing__dot'></div>
		<div className='typing__dot'></div>
		<div className='typing__dot'></div>
	</div>
);

export default Typing;